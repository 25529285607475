import React from 'react';
import { useTranslation } from '@components/translation-context/TranslationContext';

const Translation = (props: any) => {
  // Attributes
  const { translate } = useTranslation();
  return (
    <>
      {translate(props.children)}
    </>
  )
}

export default Translation;