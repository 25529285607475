import React from 'react';
import PageBody from '@components/page-body';
import PageContainer from '@components/page-container';
import Stepper from '@components/stepper';
import Box from '@components/box';
import TranslationView from '@components/translation';
import { useCompany } from '@modules/employee/modules/landing/components/context/companyContext';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import './style.css';

const Error = () => {
  //Attributes
  const { company } = useCompany();
  const { configuration } = useConfiguration();

  //Render
  return (
    <div className='error-request-container'>
      <PageBody>
        <PageContainer>
          <Box type='boxlined'>
            <Box type='boxinside'>
              <h1>
                <TranslationView>errorRequest</TranslationView>
              </h1>
              <div className='content'>
                <p>
                  <TranslationView>errrorBodyRequest1</TranslationView>
                </p>
              </div>
            </Box>
          </Box>
          <Stepper
            step={(configuration?.screens?.length ?? 7) - 1}
            steps={configuration?.screens?.length}
          />
        </PageContainer>
      </PageBody>
    </div>
  );
};

export default Error;
