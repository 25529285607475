import React, { FC, useState } from "react";
import Translations from './assets/translations.json';
import { TranslationContext } from "./TranslationContext";

const TranslationContextProvider: FC = ({ children }) => {
    const [language, setLanguage] = useState("fr");

    function changeLanguage(language: string) {
        setLanguage(language);
    }

    function translate(key: string) {
        const translations: Record<string, Record<string, string>> = Translations;

        try {
            return translations[key][language];
        }

        catch{
            return `${key}`;
        }
    }

    // Render
    return (
        <TranslationContext.Provider value={{ language: language, changeLanguage: changeLanguage, translate: translate }}>
            {children}
        </TranslationContext.Provider>
    );
}

export default TranslationContextProvider;