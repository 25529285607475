import React from 'react';
import './style.css'

const PageBody = ({ children }: any) => {
    return (
        <div className='page-body-container'>
            {children}
        </div>
    )
}

export default PageBody;