// modules
import React, { Fragment, useEffect } from "react";

// style
import "./style.css";
import InputProps from "./props";
import { useTranslation } from "@components/translation-context/TranslationContext";
import ReactDatePicker from "react-datepicker";

const Input = (props: InputProps) => {
	// Attributes
	const { name, type, placeholder, value, onChange, onFocus, autoFocus, onKeyPress, error, register, formProps, errorMessage, isHidden, setValue, minValue, style, maxValue } = props;

	const { translate } = useTranslation();

	const ref = register && register(formProps);

	// Effects
	useEffect(() => {
		if (autoFocus) {
			ref?.current?.focus();
		}
	});

	// Functions
	function handleChange(event: any) {
		if (setValue) {
			setValue(name, event?.target?.value);
		}
		onChange(event);
	}

	if (isHidden) {
		return <Fragment />;
	}

	//ExampleCustomInput in readonly to avoid keyboard to opening with the DatePicker
	const ExampleCustomInput = ({ value, onClick }: any) => (
		<div className="input-container" style={style} onClick={onClick}>
			<input name={name} readOnly className={`${type === "textarea" ? "textarea" : ""} ${error ? "input-error" : ""}`} onFocus={onFocus} type={"text"} value={value ? value : undefined} placeholder={placeholder} onKeyPress={onKeyPress} autoComplete="off" />
			<p className="error">{error && errorMessage ? translate(errorMessage) : " "}</p>
		</div>
	);

	if (type === "date") {
		return (
			<ReactDatePicker
				popperPlacement="bottom"
				popperModifiers={{
					flip: {
						behavior: ["bottom"], // don't allow it to flip to be above
					},
					preventOverflow: {
						enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
					},
					hide: {
						enabled: false, // turn off since needs preventOverflow to be enabled
					},
				}}
				minDate={minValue}
				maxDate={maxValue}
				customInput={<ExampleCustomInput />}
				onKeyDown={onKeyPress}
				onFocus={onFocus}
				placeholderText={placeholder}
				name={name}
				className="input-container"
				dateFormat="dd/MM/yyyy"
				selected={value !== undefined ? new Date(value as any) : value}
				onChange={(date) => handleChange({ target: { value: date, type: "date" } })}
			/>
		);
	}

	return (
		<div style={style} className="input-container">
			<input ref={ref} name={name} className={`${type === "textarea" ? "textarea" : ""} ${error ? "input-error" : ""}`} onFocus={onFocus} type={type} value={value} placeholder={placeholder} onChange={handleChange} onKeyPress={onKeyPress} autoComplete="nope" style={style} />
			<p className="error">{error && errorMessage ? translate(errorMessage) : " "}</p>
		</div>
	);
};

export default Input;
