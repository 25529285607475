import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Certificate from "./modules/certificates";
import Landing from "./modules/landing";
import PageBackground from "@components/page-background";
import PageHeader from "@components/page-header";
import UnderBox from "@components/underbox";
import logo from "../../assets/logo.png";
import Detail from "./modules/detail";
import CompanyContextProvider from "./modules/landing/components/context/companyContextProvider";
import ConfigurationContextProvider from "@components/congifuration-context/ConfigurationContextProvider";
import NotFound from "./modules/not-found";
import "./style.css";
import CertificateContextProvider from "./modules/certificates/components/context/certificateContextProvider";
import { useTranslation } from "@components/translation-context/TranslationContext";
import EntryTypesContextProvider from "@components/entry-types-context/EntryTypeContextProvider";

const Employee = () => {
  // Attributes
  const history = useHistory();
  const { language, changeLanguage } = useTranslation();

  // Effects
  useEffect(() => {
    if (!history.location.pathname.includes("certificate")) {
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    const lg = navigator.language;
    changeLanguage(lg?.includes("fr") ? "fr" : "nl");
  }, []);

  //Render
  return (
    <CompanyContextProvider>
      <ConfigurationContextProvider>
        <EntryTypesContextProvider>
          <CertificateContextProvider>
            <PageBackground>
              <PageHeader logo={logo} />
              <div className="employee-container">
                <Switch>
                  <Route path="/not-found" component={NotFound} />

                  <Route path="/employee/:id" component={Certificate} />
                  <Route path="/employee" component={Detail} />

                  <Route exact path="/" component={Landing} />

                  <Route default render={() => <Redirect to="/not-found" />} />
                </Switch>
                <UnderBox />
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  color: "white",
                  fontSize: 13,
                  fontWeight: 500,
                  marginBottom: 16,
                }}
              >
                © 2021 - Medicheck.io -
                <a
                  href={"https://privacy.mycertificate.be/" + language}
                  target="_blank"
                  style={{
                    marginLeft: 4,
                    textDecoration: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Privacy policy
                </a>
              </div>
            </PageBackground>
          </CertificateContextProvider>
        </EntryTypesContextProvider>
      </ConfigurationContextProvider>
    </CompanyContextProvider>
  );
};

export default Employee;
