export const customStyles: any = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "39px",
    height: "39px",
    boxShadow: state.isFocused ? null : null,
    marginBottom: "20px",
    borderRadius: "none",
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: "visible",
    fontSize: "15px",
    fontWeight: "300",
    height: "39px",
    padding: "0px 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: "0px",
    height: "39px",
    paddingTop: "0px",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: "15px",
    fontWeight: "300",
    margin: "0px",
    alignItem: "center",
  }),
  singleValue: (provided: any, state: any) => ({
    fontSize: "15px",
    margin: "0px",
  }),
};

export const customStylesError: any = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "#eb516d",
    minHeight: "39px",
    height: "39px",
    boxShadow: state.isFocused ? null : null,
    marginBottom: "20px",
    borderRadius: "none",
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: "visible",
    fontSize: "15px",
    fontWeight: "300",
    height: "39px",
    padding: "0px 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: "0px",
    height: "39px",
    paddingTop: "0px",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: "15px",
    fontWeight: "300",
    margin: "0px",
    alignItem: "center",
  }),
  singleValue: (provided: any, state: any) => ({
    fontSize: "15px",
    margin: "0px",
  }),
};
