import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { EntryTypeDTO, TypeOfEntry } from "@dtos/EntryTypeDTO";

export interface IEntryTypeContext {
  entryTypes: EntryTypeDTO[] | undefined;
  currentEntryType: TypeOfEntry | undefined;
  setCurrentEntryType: Dispatch<SetStateAction<TypeOfEntry | undefined>>;
  entryTypeQuery: any;
}

export const EntryTypeContext = createContext<IEntryTypeContext>({
  entryTypes: undefined,
  currentEntryType: undefined,
  setCurrentEntryType: () => {},
  entryTypeQuery: undefined,
});

export function useEntryTypes() {
  return useContext(EntryTypeContext);
}
