export enum LANGUAGE {
    UNKNOWN = -1,
    FR,
    NL
}

export default interface EmployeeDTO {
    id: string
    companyId: string | undefined,
    matricule: string,
    firstName: string,
    lastName: string,
    phoneNumber: '',
    address: '',
    language: LANGUAGE,
}