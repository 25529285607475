import CertificateDTO from "@dtos/CertificateDTO";
import Axios from "axios";
import Service from "./Service";

async function post(certificate: CertificateDTO, certificateId: string) {
	if (certificate.employee) {
		certificate.employee.companyId = await Service.getCompanyId();
	}
	return Service.execute(Axios.post(`${Service.API_URL}/v1/api/companies/${await Service.getCompanyId()}/certificate/${certificateId}/requests`, certificate));
}

export default { post };
