import EmployeeDTO from "@dtos/EmployeeDTO";
import Axios from "axios";
import Service from "./Service";

async function post(employee: EmployeeDTO) {
	employee.companyId = await Service.getCompanyId();
	return Service.execute(Axios.post(`${Service.API_URL}/v1/api/companies/${await Service.getCompanyId()}/employees`, employee));
}

export default { post };
