import CompanyDTO from '@dtos/CompanyDTO';
import { createContext, useContext } from 'react';

interface ICompanyContext {
    company: CompanyDTO;
}

export const CompanyContext = createContext<ICompanyContext>({
    company: {
        key: "",
        logoURL: "",
        medicheckId: "",
        hrEmails: "",
        name: ""
    }
});

export function useCompany() {
    return useContext(CompanyContext);
}