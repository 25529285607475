import { createContext, useContext } from 'react';

export interface TranslationContextValue {
    language: string;
    translate: (key: string) => string;
    changeLanguage: (language: string) => void;
}

export const TranslationContext = createContext<TranslationContextValue>({
    language: 'en',
    translate: (key: string) => '',
    changeLanguage: (language: string) => { }
});

export function useTranslation() {
    return useContext(TranslationContext);
}
