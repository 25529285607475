import { useTranslation } from "@components/translation-context/TranslationContext";
import React from "react";
import "./style.css";

const PageHeader = ({ logo }: any) => {
  //Attributes
  const { language, changeLanguage } = useTranslation();

  return (
    <div className="page-header">
      <img src={logo} className="logo" alt="logo" />

      <ul className="lang">
        <li
          onClick={() => changeLanguage("fr")}
          className={language === "fr" ? "active" : ""}
        >
          <span>FR</span>
        </li>
        <li className="separator">|</li>
        <li
          onClick={() => changeLanguage("nl")}
          className={language === "nl" ? "active" : ""}
        >
          <span>NL</span>
        </li>
      </ul>
    </div>
  );
};

export default PageHeader;
