import React from 'react';
import { hot } from 'react-hot-loader';
import './App.css';
import { TranslationContextProvider } from './components/translation-context';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

// modules

import "react-datepicker/dist/react-datepicker.css";
import { ReactQueryConfigProvider } from 'react-query';
import Employee from '@modules/employee';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  // Rendering
  return (
    <ReactQueryConfigProvider config={{ queries: { refetchOnMount: true, refetchOnWindowFocus: false } }}>
      <TranslationContextProvider>
        <BrowserRouter>
          <Switch>
            <Route path='/employee' component={Employee} />
            <Route path='/' component={Employee} />
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </TranslationContextProvider>
    </ReactQueryConfigProvider>
  );
}


export default hot(module)(App);
