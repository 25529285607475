import { createContext, useContext } from 'react';
import ConfigurationDTO from '@dtos/ConfigurationDTO';


export interface IConfigurationContext {
    configuration: ConfigurationDTO | undefined;
    query: any;
}

export const ConfigurationContext = createContext<IConfigurationContext>({
    configuration: undefined,
    query: undefined
});

export function useConfiguration() {
    return useContext(ConfigurationContext);
}