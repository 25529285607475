import React from 'react';
import Translation from '@components/translation';
import Box from '@components/box';

const NotFound = () => {
    return (
        <div>
            <Box type="boxlined">
                <Box type="boxinside">
                    <h1>
                        <span>
                            <Translation>notFoundTitle</Translation>
                        </span>
                    </h1>
                    <p>
                        <span>
                            <Translation>notFoundBody</Translation>
                        </span>
                    </p>
                </Box>
            </Box>
        </div>
    )
}

export default NotFound