import React from "react";
import './style.css'

const Stepper = ({ step, steps }: any) => {
  return (
    <ul className="steps">
      {Array(steps)
        .fill(0, 0, steps)
        .map((o, index) => (
          <li key={index} className={index <= step ? "active" : ""}>
            .
          </li>
        ))}
    </ul>
  );
};

export default Stepper;
