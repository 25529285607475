// modules
import React from 'react';

// style
import './style.css'

const PageContainer = ({ children }: any) => {
    return (
        <div className='container' style={{height: 500}}>
            {children}
        </div>
    )
}

export default PageContainer;