import Translation from "@components/translation";
import React from "react";
import "./style.css";

const UnderBox = () => {

  return (
    <div className="underbox">
      <p>
        <span>
          <Translation>
            helpLabel
          </Translation>
        </span>
        <br />
        <span>
          <Translation>
            callLabel
          </Translation>
          0800 39 866
        </span>
      </p>
    </div>
  );
};

export default UnderBox;
