import { getCompanyIdByName } from '@utils/index';

let errors: string[] = [];

function config() {
	const access_token = localStorage.getItem('ACCESS_TOKEN');
	return { headers: { Authorization: `bearer ${access_token}` } };
}

async function getCompanyId() {
	//first split to remove https second to decompose the url
	const subdomain = window.location.origin.split('//')[1].split('.');

	// if 1st subdomain === 'www' then select the 2nd element of the array
	let companyName = subdomain[0] === 'www' ? subdomain[1] : subdomain[0];

	// for staging environment
	if (companyName.toLocaleLowerCase() === 'stg') {
		companyName = 'medicheck';
	}

	const company = await getCompanyIdByName(companyName);
	return company.id;
}

async function execute(service: Promise<any>) {
	try {
		return await service;
	} catch (e) {
		if (e?.response?.status === 401) {
			localStorage.clear();
			window.location.pathname = '/employee';
		} else {
			return e?.response;
		}
	}
}

const API_URL = process.env.REACT_APP_RESOURCE_SERVER;

export default { errors, getCompanyId, execute, config, API_URL };
