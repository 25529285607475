import React, { FC } from "react";
import { useQuery } from "react-query";
import { ConfigurationContext } from "./ConfigurationContext";
import ConfigurationService from "@services/ConfigurationService";

const ConfigurationContextProvider: FC = ({ children }) => {
  //Attributes
  const query = useQuery("getConfig", getConfig);

  //Methods
  async function getConfig(): Promise<any> {
    try {
      const response = await ConfigurationService.get();
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  return (
    <ConfigurationContext.Provider
      value={{ configuration: query.data, query: query }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationContextProvider;
