import { useTranslation } from "@components/translation-context/TranslationContext";
import React from "react";
import SearchableSelectProps from "./props";
import Select from "react-select";
import { customStyles, customStylesError } from "./styles";
import { Controller } from "react-hook-form";

const SearchableSelect = (props: SearchableSelectProps) => {
  // Attributes
  const {
    name,
    placeholder,
    value,
    onChange,
    options,
    error,
    rules,
    errorMessage,
    isHidden,
    control,
  } = props;

  const { translate } = useTranslation();
  const computedOptions = options?.map((option: any) => ({
    value: option.value,
    label: option.label,
  }));

  // Methods
  function handleChange(event: any) {
    onChange(event);
  }

  function getDefaultValues(){
    return computedOptions.find(
      (option: any) => option.value === value
    );
  }

  if (isHidden) {
    return <React.Fragment />;
  }

  return (
    <div className="select-container">
      <Controller
        name={name ?? ""}
        control={control}
        rules={rules}
        render={() => (
          <Select
            name={name}
            styles={error && errorMessage ? customStylesError : customStyles}
            defaultValue={getDefaultValues()}
            onChange={handleChange}
            placeholder={placeholder}
            options={computedOptions}
            isSearchable={true}
          />
        )}
      />
      <p className="error">
        {error && errorMessage ? translate(errorMessage) : ""}
      </p>
    </div>
  );
};

export default SearchableSelect;
