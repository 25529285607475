import Box from '@components/box';
import Button from '@components/button';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import { useEntryTypes } from '@components/entry-types-context/EntryTypeContext';
import InputGroup from '@components/input-group';
import Stepper from '@components/stepper';
import { useTranslation } from '@components/translation-context/TranslationContext';
import TranslationView from '@components/translation';
import { actions } from '@reducers/certificateReducer';
import { findNextScreen } from '@utils/index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import Input from '@components/input';
import Select from '@components/select';
import { useCertificate } from '../certificates/components/context/certificateContext';
import { TypeOfEntry } from '@dtos/EntryTypeDTO';
import SearchableSelect from '@components/searchable-select';

const AbsenceDeclaration = () => {
  //Attributes
  const { id } = useParams<any>();
  const history = useHistory();
  const { translate } = useTranslation();
  const { configuration } = useConfiguration();
  const { certificate, dispatch } = useCertificate();
  const { register, errors, handleSubmit, setValue, getValues, control } = useForm();
  const screenConfig = configuration?.screens.find(
    (elt) => elt.name === 'absence-declaration'
  );
  const { currentEntryType } = useEntryTypes();

  const incapacityReasons = configuration?.incapacityTypes
    .filter((elt) => elt.isActive)
    .map((elt) => ({ value: elt.name, label: translate(elt.name) }))
    .sort((a, b) => (a.label == 'Illness' ? -1 : 0));

  // Effects
  useEffect(() => {
    register('startDate', {
      required: screenConfig?.fields.find(
        (elt) => elt.name.toLowerCase() === 'startdate'
      )?.isRequired,
    });

    if (certificate.startDate) {
      setValue('startDate', certificate.startDate);
    }
  }, []);

  //Functions
  function handleOnClick() {
    if (screenConfig) {
      if (currentEntryType === TypeOfEntry.ABSENCE_DECLARATION) {
        const nextScreen = findNextScreen(configuration, 4);
        history.push(`/employee/${id}/${nextScreen?.name}`);
      } else {
        const nextScreen = findNextScreen(configuration, 3);
        history.push(`/employee/${id}/${nextScreen?.name}`);
      }
    }
  }

  function handleOnClickBack() {
    history.goBack();
  }

  function handleOnChange(event: any, type: string) {
    let action = { type, payload: event?.target.value };

    if (type === actions.startDateChanged) {
      action = { type, payload: new Date(event.target.value) };
      setValue('startDate', new Date(event.target.value));
    }

    dispatch(action);
  }

  function compareDates(date1?: Date, date2?: Date) {
    if (date1 && date2) {
      if (
        new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()) >
        new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
      ) {
        return true;
      }
      return false;
    }

    return false;
  }

  function validateIncapacityReason(){
    return !screenConfig?.fields.find(
      (elt) => elt.name.toLowerCase() === 'incapacityreason'
    )?.isRequired || certificate.incapacityReason !== '';
  }

  //Render
  return (
    <div className='incapacity-reason-container'>
      <Box>
        <Box type='boxinside'>
          <h3>
            <TranslationView>titleCertificate</TranslationView>
          </h3>

          <div className='incapacity-reason-form'>
            <InputGroup>
              <Input
                type='date'
                name='startDate'
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'startdate'
                  )?.isActive
                }
                value={
                  getValues('startDate')
                    ? new Date(getValues('startDate'))
                    : certificate?.startDate?.toISOString()
                }
                setValue={setValue}
                onChange={(event: any) =>
                  handleOnChange(event, actions.startDateChanged)
                }
                placeholder={translate('startDate')}
                style={{ width: '100%' }}
                error={
                  compareDates(certificate.startDate, certificate.endDate) ||
                  errors['startDate']
                    ? 'error'
                    : undefined
                }
                errorMessage={
                  errors['startDate']?.type === 'required'
                    ? translate('errorInput')
                    : compareDates(
                        certificate.startDate,
                        certificate.endDate
                      ) || errors['startDate']
                    ? translate('startDateError')
                    : undefined
                }
              />
              <div style={{ width: 50 }}></div>
              <SearchableSelect
                name='incapacityReason'
                options={incapacityReasons}
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'incapacityreason'
                  )?.isActive
                }
                value={certificate.incapacityReason}
                onChange={(event: any) =>
                  dispatch({
                    type: actions.incapacityReasonChanged,
                    payload: event?.value,
                  })
                }
                error={errors.incapacityReason}
                placeholder={translate('incapacityReasonPlaceholder')}
                errorMessage={
                  errors.incapacityReason?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                rules={{
                  validate: () => validateIncapacityReason(),
                }}
                control={control}
              />
            </InputGroup>

            <InputGroup>
              <Input
                type='text'
                name='doctorName'
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'doctorname'
                  )?.isActive
                }
                value={
                  getValues('doctorName')
                    ? getValues('doctorName')
                    : certificate.doctorName
                }
                setValue={setValue}
                onChange={(event: any) =>
                  handleOnChange(event, actions.doctorNameChanged)
                }
                placeholder={translate('doctorNamePlaceholder')}
                error={errors.doctorName}
                errorMessage={
                  errors.doctorName?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                register={register}
                formProps={{
                  required: screenConfig?.fields.find(
                    (elt) => elt.name.toLowerCase() === 'doctorname'
                  )?.isRequired,
                }}
              />
              <div style={{ width: 50 }}></div>
              {/* this is an input with 0 opacity for the layout of the box */}
              <Input
                style={{ opacity: 0 }}
                type='text'
                name='doctorName'
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'doctorname'
                  )?.isActive
                }
                value={
                  getValues('doctorName')
                    ? getValues('doctorName')
                    : certificate.doctorName
                }
                setValue={setValue}
                onChange={(event: any) =>
                  handleOnChange(event, actions.doctorNameChanged)
                }
                placeholder={translate('doctorNamePlaceholder')}
                error={errors.doctorName}
                errorMessage={
                  errors.doctorName?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                register={register}
                formProps={{
                  required: screenConfig?.fields.find(
                    (elt) => elt.name.toLowerCase() === 'doctorname'
                  )?.isRequired,
                }}
              />
            </InputGroup>
          </div>

          <Button type='valider' onClick={handleSubmit(handleOnClick)}>
            validerPlaceholder
          </Button>

          <Button type='retour' onClick={handleOnClickBack}>
            retourPlaceholder
          </Button>
        </Box>
      </Box>
      <Stepper step={4} steps={configuration?.screens?.length} />
    </div>
  );
};

export default AbsenceDeclaration;
