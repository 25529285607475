import React, { FC } from 'react';
import CompanyService from '@services/CompanyService';
import { CompanyContext } from './companyContext';
import { useQuery } from 'react-query';

const CompanyContextProvider: FC = ({ children }) => {
    //Attributes
    const query = useQuery('getCompany', getCompany);

    //Functions
    async function getCompany() {
        try {
            const response = await CompanyService.get();
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    //Render
    return (
        <CompanyContext.Provider value={{ company: query.data }}>
            {children}
        </CompanyContext.Provider>

    )
}

export default CompanyContextProvider;

