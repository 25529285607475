import React from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import AbsenceDeclaration from "../absence-declaration";
import CertificateContextProvider from "./components/context/certificateContextProvider";
import Coordinates from "./modules/coordinates";
import Document from "./modules/document";
import IncapacityReason from "./modules/incapacity-reason";
import Request from "./modules/request";
import Success from "./modules/success";
import Summary from "./modules/summary";

const Certificate = () => {
  //Attributes
  const { id }: any = useParams();

  //Render
  return (
    <Switch>
      <Route path={`/employee/:id/coordinates`} component={Coordinates} />
      <Route
        path={`/employee/:id/absence-declaration`}
        component={AbsenceDeclaration}
      />
      <Route
        path={`/employee/:id/incapacity-reason`}
        component={IncapacityReason}
      />
      <Route path={`/employee/:id/document`} component={Document} />
      <Route path={`/employee/:id/summary`} component={Summary} />
      <Route path={`/employee/:id/success`} component={Success} />
      <Route
        path="/employee/:id/certificate/:certificateId"
        component={Request}
      />
      <Route
        default
        render={() => <Redirect to={`/employee/${id}/coordinates`} />}
      />
    </Switch>
  );
};

export default Certificate;
