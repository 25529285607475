// modules
import React from "react";
import Props from "./props";

// style
import "./style.css";

const Box = (props: Props) => {
  // Attributes
  const { type, children } = props;

  // Methods
  function findClassName() {
    let className = "box";

    if (type) {
      className += ` ${type}`;
    }

    if (type === "boxinside") {
      className = `${type}`;
    }

    return className;
  }

  // Render
  return <div className={findClassName()}>{children}</div>;
};

export default Box;
