export interface EntryTypeDTO {
    configurationId: string;
    type: TypeOfEntry;
    isActive: boolean;
  }
  
  export enum TypeOfEntry {
    UNKNOWN = -1,
    ABSENCE_DECLARATION,
    MEDICAL_CERTIFICATE,
  }
  