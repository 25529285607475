import './style.css';
import Box from '@components/box';
import Button from '@components/button';
import Input from '@components/input';
import InputGroup from '@components/input-group';
import Stepper from '@components/stepper';
import TranslationView from '@components/translation';
import { useTranslation } from '@components/translation-context/TranslationContext';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { actions } from '@reducers/certificateReducer';
import { useCertificate } from '@modules/employee/modules/certificates/components/context/certificateContext';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import { CERTIFICATE_TYPE } from '@dtos/CertificateDTO';
import { findNextScreen } from '@utils/index';
import SearchableSelect from '@components/searchable-select';

const IncapacityReason = () => {
  //Attributes
  const { id } = useParams<any>();
  const history = useHistory();
  const { translate } = useTranslation();
  const { configuration } = useConfiguration();
  const { certificate, dispatch } = useCertificate();
  const { register, errors, handleSubmit, setValue, getValues, control } = useForm();
  const screenConfig = configuration?.screens.find(
    (elt) => elt.name === 'incapacity-reason'
  );

  const incapacityReasons = configuration?.incapacityTypes
    .filter((elt) => elt.isActive)
    .map((elt) => ({ value: elt.name, label: translate(elt.name) }))
    .sort((a, b) => (a.label == 'Illness' ? -1 : 0));
  const certificateTypes = configuration?.certificateTypes
    .filter((elt) => elt.isActive)
    .map((elt) => ({ value: elt.type, label: translate(elt.name) }));

  const AuthorizedExitOptions = [
    { value: 1, label: translate('sortieTrue') },
    { value: 0, label: translate('sortieFalse') },
  ];

  // Effects
  useEffect(() => {
    register('startDate', {
      required: screenConfig?.fields.find(
        (elt) => elt.name.toLowerCase() === 'startdate'
      )?.isRequired,
    });
    register('endDate', {
      required: screenConfig?.fields.find(
        (elt) => elt.name.toLowerCase() === 'enddate'
      )?.isRequired,
    });

    if (certificate.startDate) {
      setValue('startDate', certificate.startDate);
    }
    if (certificate.endDate) {
      setValue('endDate', certificate.endDate);
    }
  }, []);

  //Functions
  function handleOnClick() {
    if (
      screenConfig &&
      !compareDates(certificate.startDate, certificate.endDate)
    ) {
      const nextScreen = findNextScreen(configuration, 3);
      history.push(`/employee/${id}/${nextScreen?.name}`);
    }
  }
  function handleOnClickBack() {
    history.goBack();
  }

  function handleOnChange(event: any, type: string) {
    let action = { type, payload: event?.target.value };

    if (type === actions.startDateChanged || type === actions.endDateChanged) {
      action = { type, payload: new Date(event.target.value) };
      if (type === actions.startDateChanged) {
        setValue('startDate', new Date(event.target.value));
        if (certificate.certifType === CERTIFICATE_TYPE.JOUR_SANS_CERTIF) {
          setValue('endDate', undefined);
          dispatch({ type: actions.endDateChanged, payload: undefined });
        }
      } else {
        setValue('endDate', new Date(event.target.value));
      }
    }

    dispatch(action);
  }

  function handleOnChangeCertificateType(event: any) {
    var certifType = parseInt(event?.value);
    dispatch({
      type: actions.certifTypeChanged,
      payload: certifType,
    });

    if (
      certifType === CERTIFICATE_TYPE.JOUR_SANS_CERTIF &&
      certificate.startDate !== undefined &&
      certificate.endDate !== undefined &&
      certificate.startDate !== certificate.endDate
    ) {
      setValue('endDate', undefined);
      dispatch({ type: actions.endDateChanged, payload: undefined });
    }
  }

  function compareDates(date1?: Date, date2?: Date) {
    if (date1 && date2) {
      if (
        new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()) >
        new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
      ) {
        return true;
      }
      return false;
    }

    return false;
  }

  //Render
  return (
    <div className='incapacity-reason-container'>
      <Box>
        <Box type='boxinside'>
          <h3>
            <TranslationView>titleCertificate</TranslationView>
          </h3>

          <div className='incapacity-reason-form'>
            <InputGroup>
              <Input
                type='date'
                name='startDate'
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'startdate'
                  )?.isActive
                }
                value={
                  getValues('startDate')
                    ? new Date(getValues('startDate'))
                    : certificate?.startDate?.toISOString()
                }
                setValue={setValue}
                onChange={(event: any) =>
                  handleOnChange(event, actions.startDateChanged)
                }
                placeholder={translate('startDate')}
                style={{ width: '100%' }}
                error={
                  compareDates(certificate.startDate, certificate.endDate) ||
                  errors['startDate']
                    ? 'error'
                    : undefined
                }
                errorMessage={
                  errors['startDate']?.type === 'required'
                    ? translate('errorInput')
                    : compareDates(
                        certificate.startDate,
                        certificate.endDate
                      ) || errors['startDate']
                    ? translate('startDateError')
                    : undefined
                }
              />
              <div style={{ minWidth: 25 }} />
              <Input
                type='date'
                name='endDate'
                minValue={certificate.startDate}
                maxValue={
                  certificate.certifType === CERTIFICATE_TYPE.JOUR_SANS_CERTIF
                    ? certificate.startDate
                    : undefined
                }
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'enddate'
                  )?.isActive
                }
                value={
                  getValues('endDate')
                    ? getValues('endDate')
                    : certificate?.endDate?.toISOString()
                }
                setValue={setValue}
                onChange={(event: any) =>
                  handleOnChange(event, actions.endDateChanged)
                }
                placeholder={translate('endDate')}
                style={{ width: '100%' }}
                error={
                  compareDates(certificate.startDate, certificate.endDate) ||
                  errors['endDate']
                    ? 'error'
                    : undefined
                }
                errorMessage={
                  errors['endDate']?.type === 'required'
                    ? translate('errorInput')
                    : compareDates(
                        certificate.startDate,
                        certificate.endDate
                      ) || errors['endDate']
                    ? translate('endDateError')
                    : undefined
                }
              />
            </InputGroup>

            <InputGroup>
              <SearchableSelect
                name='incapacityReason'
                options={incapacityReasons}
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'incapacityreason'
                  )?.isActive
                }
                value={certificate.incapacityReason}
                onChange={(event: any) =>
                  dispatch({
                    type: actions.incapacityReasonChanged,
                    payload: event?.value,
                  })
                }
                error={errors.incapacityReason}
                placeholder={translate('incapacityReasonPlaceholder')}
                errorMessage={
                  errors.incapacityReason?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                rules={{
                  validate: () => !screenConfig?.fields.find(
                    (elt) => elt.name.toLowerCase() === 'incapacityreason'
                  )?.isRequired || certificate.incapacityReason !== '',
                }}
                control={control}
              />
              <div style={{ width: 50 }}></div>
              <SearchableSelect
                name='certificateType'
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'certificatetype'
                  )?.isActive
                }
                value={certificate.certifType}
                options={certificateTypes}
                onChange={(event: any) => handleOnChangeCertificateType(event)}
                placeholder={translate('certificateTypePlaceholder')}
                error={errors.certificateType}
                errorMessage={
                  errors.certificateType?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                rules={{
                  validate: () => !screenConfig?.fields.find(
                    (elt) => elt.name.toLowerCase() === 'certificatetype'
                  )?.isRequired || certificate.certifType !== CERTIFICATE_TYPE.UNKNOWN,
                }}
                control={control}
              />
            </InputGroup>

            <InputGroup>
              <SearchableSelect
                name='authorizedExit'
                value={certificate.authorizedExit}
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'authorizedexit'
                  )?.isActive
                }
                options={AuthorizedExitOptions}
                onChange={(event: any) =>
                  dispatch({
                    type: actions.authorizedExitChanged,
                    payload: parseInt(event?.value),
                  })
                }
                placeholder={translate('authorizedExitPlaceholder')}
                error={errors.authorizedExit}
                errorMessage={
                  errors.authorizedExit?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                rules={{
                  validate: () => !screenConfig?.fields.find(
                    (elt) => elt.name.toLowerCase() === 'authorizedexit'
                  )?.isRequired || certificate.authorizedExit !== -1,
                }}
                control={control}
              />
              <div style={{ width: 50 }}></div>
              <Input
                type='text'
                name='doctorName'
                isHidden={
                  !screenConfig?.fields?.find(
                    (elt) => elt.name.toLowerCase() === 'doctorname'
                  )?.isActive
                }
                value={
                  getValues('doctorName')
                    ? getValues('doctorName')
                    : certificate.doctorName
                }
                setValue={setValue}
                onChange={(event: any) =>
                  handleOnChange(event, actions.doctorNameChanged)
                }
                placeholder={translate('doctorNamePlaceholder')}
                error={errors.doctorName}
                errorMessage={
                  errors.doctorName?.type === 'pattern'
                    ? translate('patternError')
                    : translate('errorInput')
                }
                register={register}
                formProps={{
                  required: screenConfig?.fields.find(
                    (elt) => elt.name.toLowerCase() === 'doctorname'
                  )?.isRequired,
                }}
              />
            </InputGroup>
          </div>

          <Button type='valider' onClick={handleSubmit(handleOnClick)}>
            validerPlaceholder
          </Button>

          <Button type='retour' onClick={handleOnClickBack}>
            retourPlaceholder
          </Button>
        </Box>
      </Box>
      <Stepper
        step={(screenConfig?.order ?? 0) + 1}
        steps={configuration?.screens?.length}
      />
    </div>
  );
};

export default IncapacityReason;
