import './style.css'
import React from 'react';
import SuggestionProps from './props';

const Suggestion = (props: SuggestionProps) => {
    const {
        suggestion,
        handleSelect,
    } = props;

    const {
        id,
        structured_formatting: { main_text, secondary_text }
    } = suggestion;

    return (
        <li className="suggestionLi" key={id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
    );
}

export default Suggestion;