import { createContext, useContext } from 'react';
import CertificateDTO from '@dtos/CertificateDTO';
import { initialCertificate } from '@reducers/certificateReducer'

interface ICertificateContext {
    certificate: CertificateDTO,
    dispatch: any
}

export const CertificateContext = createContext<ICertificateContext>({
    certificate: initialCertificate,
    dispatch: () => { }
});

export function useCertificate() {
    return useContext(CertificateContext);
}