import './style.css';
import React, { useState } from 'react';
import Box from '@components/box';
import { useTranslation } from '@components/translation-context/TranslationContext';
import Button from '@components/button';
import Translation from '@components/translation';
import { useCertificate } from '../../../../components/context/certificateContext';
import { actions } from '@reducers/certificateReducer';
import BusyIndicator from '@components/busy-indicator';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import CertificateService from '@services/CertificateService';
import RequestService from '@services/RequestService';
import CertificateDTO from '@dtos/CertificateDTO';

const Request = () => {
  //Attributes
  const history = useHistory();
  const { translate } = useTranslation();
  const { id, certificateId } = useParams();
  const [isBusy, setIsBusy] = useState(false);
  const { certificate, dispatch } = useCertificate();
  const query = useQuery('getCertificate', getCertificate);

  // Functions
  async function getCertificate() {
    try {
      setIsBusy(true);
      const response = await CertificateService.get(id, certificateId);
      if (response.data) {
        return response.data;
      } else {
        throw 'already launched';
      }
    } catch (e) {
      history.push(`/employee/${id}/certificate/${certificateId}/error`);
    } finally {
      setIsBusy(false);
    }
  }

  function handleCommentForMedicheck(event: any, type: string) {
    const action = { type, payload: event.target.value };
    const actionMedicehckComment = {
      type: actions.commentForMedicheckChanged,
      payload: event.target.value,
    };
    dispatch(actionMedicehckComment);
    dispatch(action);
  }

  function handleOnChangeCommentDoctor(event: any) {
    const action = {
      type: actions.commentForDoctorChanged,
      payload: event.target.value,
    };
    dispatch(action);
  }

  async function handleOnClick() {
    setIsBusy(true);
    try {
      const newCertif: CertificateDTO = {
        ...query.data,
        commentForDoctor: certificate.commentForDoctor,
        commentForMedicheck: certificate.commentForMedicheck,
        checkRequestReason: certificate.commentForMedicheck,
      };
      const resp = await RequestService.post(newCertif, certificateId);
      setIsBusy(false);
      if (resp) {
        history.push(`/employee/${id}/certificate/${certificateId}/success`);
      } else {
        history.push(`/employee/${id}/certificate/${certificateId}/error`);
      }
    } catch (e) {
      throw e;
    }
  }

  //Render
  return (
    <BusyIndicator query={query} isBusy={isBusy}>
      <div>
        <Box>
          <Box type='boxinside'>
            <h3 className='manualCheckTitle'>
              <Translation>titleManualCheck</Translation>
            </h3>
            <p className='manualCheckSubtitle'>
              <Translation>subtitleManualCheck</Translation>{' '}
              {certificate.employee.firstName} {certificate.employee.lastName}
            </p>
            <textarea
              value={certificate.checkRequestReason}
              className='commentManualCheck'
              name='commentForMedicheck'
              id='commentForMedicheck'
              placeholder={translate('commentMedicheck')}
              onChange={(event: any) =>
                handleCommentForMedicheck(
                  event,
                  actions.checkRequestReasonChanged
                )
              }
            />
            <textarea
              value={certificate.commentForDoctor}
              className='commentManualCheck'
              name='commentForDoctor'
              id='commentForDoctor'
              placeholder={translate('commentDoctor')}
              onChange={(event: any) => handleOnChangeCommentDoctor(event)}
            />
            <Button type='valider' onClick={handleOnClick}>
              validerManualCheckPlaceholder
            </Button>
          </Box>
        </Box>
      </div>
    </BusyIndicator>
  );
};

export default Request;
