import React from 'react';
import './style.css'
import Props from './props';
import Translation from '@components/translation';

const Button = (props: Props) => {
  // Attributes
  const { disabled, onClick, type, isNavigation } = props;

  function findClassName() {
    let className = "button";

    if (type) {
      className += ` ${type}`;
    }

    else {
      className += ` default`;
    }

    if (isNavigation) {
      className += ' navigation';
    }


    if (disabled) {
      className += " disabled";
    }
    return className;
  }

  function handleButtonClicked() {
    if (!disabled) {
      onClick();
    }
  }

  function findTextClassName() {
    if (findClassName().includes('disabled')) {
      return 'button-text disabled'
    } else {
      return 'button-text'
    }
  }

  return (
    <div className={findClassName()} onClick={handleButtonClicked}>
      <div className={findTextClassName()}>
        <Translation>
          {props.children}
        </Translation>
      </div>
    </div>
  )
}

export default Button;