import React from 'react';

import Lottie from 'react-lottie';
import animationData from './assets/lottie-loading.json';
// style
import './style.css'

const BusyIndicator = (props: any) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if(props.isBusy)
  {
    return (
      <div className='busy-indicator-div-container'>
        <div className='busy-indicator-div'>
          <Lottie options={defaultOptions}
            height={200}
            width={200}
          />
        </div>
      </div>
    )
  }

  if (props.queries) {
    if (props.queries.find((elt: any) => elt.isLoading)) {
      return (
        <div className='busy-indicator-div-container'>

          <div className='busy-indicator-div'>
            <Lottie options={defaultOptions}
              height={200}
              width={200}
            />
          </div>
        </div>
      )
    }
  }
  if (props.query) {
    if (props.query.isLoading) {
      return (
        <div className='busy-indicator-div-container'>

          <div className='busy-indicator-div'>
            <Lottie options={defaultOptions}
              height={200}
              width={200}
            />
          </div>
        </div>
      )
    }

    if (props.query.status !== "success") {
      return <div>
        {props.query.status}
      </div>
    }
  }

  return props.children;
}

export default BusyIndicator;