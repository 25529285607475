import './style.css';
import Box from '@components/box';
import Button from '@components/button';
import Input from '@components/input';
import InputGroup from '@components/input-group';
import Select from '@components/select';
import Stepper from '@components/stepper';
import TranslationView from '@components/translation';
import { useTranslation } from '@components/translation-context/TranslationContext';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { actions } from '@reducers/certificateReducer';
import { useCertificate } from '@modules/employee/modules/certificates/components/context/certificateContext';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import SuperiorService from '@services/SuperiorService';
import CertificateDTO, { GENDER } from '@dtos/CertificateDTO';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import BusyIndicator from '@components/busy-indicator';
import PlacesAutocomplete from '@components/places-autocomplete';
import { findNextScreen } from '@utils/index';
import { useEntryTypes } from '@components/entry-types-context/EntryTypeContext';
import { TypeOfEntry } from '@dtos/EntryTypeDTO';
import SearchableSelect from '@components/searchable-select';

const Coordinates = () => {
  //Attributes
  const { id } = useParams<any>();
  const history = useHistory();
  const { translate } = useTranslation();
  const { configuration } = useConfiguration();
  const { certificate, dispatch } = useCertificate();
  const { register, errors, handleSubmit, setValue, getValues, control } = useForm();
  const superiorsQuery = useQuery(`${id}-superiors`, getSuperiors);
  const screenConfig = configuration?.screens.find(
    (elt) => elt.name === 'coordinates'
  );
  const { currentEntryType, entryTypes } = useEntryTypes();

  const genders = [
    { value: 0, label: translate('genderMale') },
    { value: 1, label: translate('genderFemale') },
  ];

  //Functions
  async function handleOnClick() {
    if (screenConfig) {
      if (
        currentEntryType === TypeOfEntry.ABSENCE_DECLARATION &&
        entryTypes?.find((elt) => elt.type === TypeOfEntry.ABSENCE_DECLARATION)
          ?.isActive
      ) {
        const nextScreen = findNextScreen(configuration, 6);
        history.push(`/employee/${id}/${nextScreen?.name}`);
      } else {
        const nextScreen = findNextScreen(configuration, 2);
        history.push(`/employee/${id}/${nextScreen?.name}`);
      }
    }
  }

  function handleOnClickRetour() {
    history.goBack();
  }

  function handleChange(event: any, type: string) {
    const action = { type, payload: event?.target.value };
    dispatch(action);
  }

  function handleChangeSelect(event: any, type: string) {
    const action = { type, payload: event?.value };
    dispatch(action);
  }

  async function getSuperiors() {
    try {
      const response = await SuperiorService.get();
      return response.data.map((elt: { fullName: any }) => {
        return { value: elt.fullName, label: elt.fullName };
      });
    } catch (e) {
      throw e;
    }
  }

  function retrieveValue(name: string) {
    if (getValues(name)) {
      return getValues(name);
    } else {
      switch (name) {
        case 'firstName':
          setValue(name, certificate.employee.firstName);
          break;
        case 'lastName':
          setValue(name, certificate.employee.lastName);
          break;
        case 'phoneNumber':
          setValue(name, certificate.employee.phoneNumber);
          break;
        case 'address':
          setValue(name, certificate.employee.address);
          break;
      }
    }
  }

  function renderInput(name: string) {
    // Attribtues
    const action = (actions as any)[`${name}Changed`];
    const errorMessage =
      errors[name]?.type === 'pattern'
        ? translate('patternError')
        : translate('errorInput');
    const isHidden = !screenConfig?.fields?.find(
      (elt) => elt.name.toLowerCase() === name.toLowerCase()
    )?.isActive;
    const formProps = {
      required: screenConfig?.fields?.find(
        (elt) => elt.name.toLowerCase() === name.toLowerCase()
      )?.isRequired,
    };

    // Render
    return (
      <Input
        type='text'
        name={name}
        isHidden={isHidden}
        value={retrieveValue(name)}
        setValue={setValue}
        onChange={(event) => handleChange(event, action)}
        placeholder={translate(`${name}Placeholder`)}
        error={errors[name]}
        errorMessage={errorMessage}
        register={register}
        formProps={formProps}
      />
    );
  }

  function renderSelect(name: string, options: Array<object>) {
    //Attributes
    const action = (actions as any)[`${name}Changed`];
    const errorMessage =
      errors[name]?.type === "pattern"
        ? translate("patternError")
        : translate("errorInput");
    const isHidden = !screenConfig?.fields?.find(
      (elt) => elt.name.toLowerCase() === name.toLowerCase()
    )?.isActive;
    let formProps = {};

    if (name === "gender") {
      formProps = {
        validate: () =>
          !screenConfig?.fields?.find(
            (elt) => elt.name.toLowerCase() === "gender"
          )?.isRequired || certificate.gender !== GENDER.UNKNOWN,
      };
    } else if (name === "superiors") {
      formProps = {
        validate: () =>
          !screenConfig?.fields?.find(
            (elt) => elt.name.toLowerCase() === "superiors"
          )?.isRequired || certificate.superior !== "",
      };
    }

    //Render
    return (
      <SearchableSelect
        value={getSelectValue(name)}
        name={name}
        isHidden={isHidden}
        options={options}
        onChange={(event: any) => handleChangeSelect(event, action)}
        placeholder={translate(`${name}Placeholder`)}
        error={errors[name]}
        errorMessage={errorMessage}
        rules={formProps}
        control={control}
      />
    );
  }

  function getSelectValue(name: string){
    if(name === "superiors"){
      return certificate["superior"];
    }
    return certificate[name as keyof CertificateDTO];
  }

  //Render
  return (
    <BusyIndicator query={superiorsQuery}>
      <div className='coordinates-container'>
        <Box>
          <Box type='boxinside'>
            <h3>
              <TranslationView>titlePersonalData</TranslationView>
            </h3>

            <div className='coordinates-form'>
              <InputGroup>
                {renderInput('firstName')}
                <div style={{ width: 50 }} />
                {renderInput('lastName')}
              </InputGroup>

              <InputGroup>
                {renderSelect('gender', genders)}
                <div style={{ width: 50 }} />
                {renderInput('phoneNumber')}
              </InputGroup>

              <InputGroup>
                <PlacesAutocomplete
                  setValue={setValue}
                  value={
                    getValues('address')
                      ? getValues('address')
                      : certificate.employee.address
                  }
                  errors={errors}
                  screenConfig={screenConfig}
                  register={register}
                />
                {/* Address input */}
                <div style={{ width: 50 }} />
                {renderSelect('superiors', superiorsQuery.data)}
              </InputGroup>
            </div>

            <Button type='valider' onClick={handleSubmit(handleOnClick)}>
              validerPlaceholder
            </Button>
            <Button type='retour' onClick={handleOnClickRetour}>
              retourPlaceholder
            </Button>
          </Box>
        </Box>
        <Stepper
          step={(screenConfig?.order ?? 0) + 1}
          steps={configuration?.screens?.length}
        />
      </div>
    </BusyIndicator>
  );
};

export default Coordinates;
