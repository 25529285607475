import CertificateDTO from "@dtos/CertificateDTO";
import Axios from "axios";
import Service from "./Service";

async function get(employeeId: string, certificateId: string) {
	return Axios.get(`${Service.API_URL}/v1/api/companies/${await Service.getCompanyId()}/employees/${employeeId}/certificates/${certificateId}`);
}

async function post(certificate: CertificateDTO, employeeId: string) {
	if (certificate.employee) {
		certificate.employee.companyId = await Service.getCompanyId();
	}
	return Service.execute(Axios.post(`${Service.API_URL}/v1/api/companies/${await Service.getCompanyId()}/employees/${employeeId}/certificates`, certificate));
}

export default { get, post };
