import CertificateDTO, { CERTIFICATE_TYPE, GENDER } from "@dtos/CertificateDTO";
import DocumentDTO from "@dtos/DocumentDTO";
import EmployeeDTO, { LANGUAGE } from "@dtos/EmployeeDTO";
import { TypeOfEntry } from "@dtos/EntryTypeDTO";

export const initialEmployee: EmployeeDTO = {
  id: "",
  companyId: "",
  matricule: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  language: LANGUAGE.UNKNOWN,
};

export const initialCertificate: CertificateDTO = {
  employee: initialEmployee,
  employeeId: initialEmployee.id,
  gender: GENDER.UNKNOWN,
  superior: "",
  hrManager: "",
  startDate: undefined,
  endDate: undefined,
  certifType: CERTIFICATE_TYPE.UNKNOWN,
  incapacityReason: "",
  authorizedExit: -1,
  doctorName: "",
  doctorAddress: "",
  urlCertificate: "",
  shouldBeChecked: false,
  completionDay: new Date(),
  isCheckRequestLaunched: false,
  checkRequestReason: "",
  entryType: TypeOfEntry.UNKNOWN,
  commentForDoctor: "",
  commentForMedicheck: "",
  fileName: "",
};

export const initialDocument: DocumentDTO = {
  file: "",
  fileName: "",
  fileExtension: "",
  fileUrl: "",
};

export const actions = {
  //EMPLOYEE
  employeeIdChanged: "employeeIdChanged",
  companyIdChanged: "companyIdChanged",
  matriculeChanged: "matriculeChanged",
  firstNameChanged: "firstNameChanged",
  lastNameChanged: "lastNameChanged",
  //CERTIFICATE
  certifFirstNameChanged: "certifFirstNameChanged",
  certifLastNameChanged: "certifLastNameChanged",
  phoneNumberChanged: "phoneNumberChanged",
  addressChanged: "addressChanged",
  languageChanged: "languageChanged",
  genderChanged: "genderChanged",
  superiorsChanged: "superiorChanged",
  hrManagerChanged: "hrManagerChanged",
  startDateChanged: "startDateChanged",
  endDateChanged: "endDateChanged",
  certifTypeChanged: "certifTypeChanged",
  incapacityReasonChanged: "incapacityReasonChanged",
  authorizedExitChanged: "authorizedExitChanged",
  doctorNameChanged: "doctorNameChanged",
  doctorAddressChanged: "doctorAdressChanged",
  urlCertificateChanged: "urlCertificateChanged",
  shouldBeCheckedChanged: "shouldBeCheckedChanged",
  completionDayChanged: "completionDayChanged",
  isCheckRequestLaunchedChanged: "isCheckRequestLaunchedChanged",
  checkRequestReasonChanged: "checkRequestReasonChanged",
  entryTypeChanged: "entryTypeChanged",
  commentForMedicheckChanged: "commentForMedicheckChanged",
  commentForDoctorChanged: "commentForDoctorChanged",
  fileNameChanged: "fileNameChanged",
};

const reducer = (state: CertificateDTO, action: any) => {
  switch (action.type) {
    case actions.companyIdChanged:
      break;

    case actions.matriculeChanged:
      return {
        ...state,
        employee: { ...state.employee, matricule: action.payload },
      };

    case actions.firstNameChanged:
      return {
        ...state,
        employee: { ...state.employee, firstName: action.payload },
      };

    case actions.lastNameChanged:
      return {
        ...state,
        employee: { ...state.employee, lastName: action.payload },
      };

    case actions.employeeIdChanged:
      return {
        ...state,
        employeeId: action.payload,
        employee: { ...state.employee, id: action.payload },
      };

    case actions.phoneNumberChanged:
      return {
        ...state,
        employee: { ...state.employee, phoneNumber: action.payload },
      };

    case actions.addressChanged:
      return {
        ...state,
        employee: { ...state.employee, address: action.payload },
      };

    case actions.languageChanged:
      return {
        ...state,
        employee: { ...state.employee, language: action.paylaod },
      };

    case actions.genderChanged:
      return { ...state, gender: action.payload };

    case actions.superiorsChanged:
      return { ...state, superior: action.payload };

    case actions.hrManagerChanged:
      return { ...state, hrManager: action.payload };

    case actions.startDateChanged:
      return { ...state, startDate: action.payload };

    case actions.endDateChanged:
      return { ...state, endDate: action.payload };

    case actions.certifTypeChanged:
      return { ...state, certifType: action.payload };

    case actions.incapacityReasonChanged:
      return { ...state, incapacityReason: action.payload };

    case actions.authorizedExitChanged:
      return { ...state, authorizedExit: action.payload };

    case actions.doctorNameChanged:
      return { ...state, doctorName: action.payload };

    case actions.doctorAddressChanged:
      return { ...state, doctorAddress: action.payload };

    case actions.urlCertificateChanged:
      return { ...state, urlCertificate: action.payload };

    case actions.shouldBeCheckedChanged:
      return { ...state, shouldBeChecked: action.payload };

    case actions.completionDayChanged:
      return { ...state, completionDay: action.payload };

    case actions.isCheckRequestLaunchedChanged:
      return { ...state, isCheckRequestLaunched: action.payload };

    case actions.checkRequestReasonChanged:
      return { ...state, checkRequestReason: action.payload };

    case actions.entryTypeChanged:
      return { ...state, entryType: action.payload };

    case actions.commentForDoctorChanged:
      return { ...state, commentForDoctor: action.payload };

    case actions.commentForMedicheckChanged:
      return { ...state, commentForMedicheck: action.payload };

    case actions.fileNameChanged:
      return { ...state, fileName: action.payload };
  }

  return state;
};

export default { actions, reducer };
