import ConfigurationDTO from "@dtos/ConfigurationDTO";
import EmployeeDTO, { LANGUAGE } from "@dtos/EmployeeDTO";
import ScreenDTO from "@dtos/ScreenDTO";
import CompanyService from "@services/CompanyService";
import EmployeeService from "@services/EmployeeService";
import Service from "@services/Service";

async function createEmployee(matricule: string = "") {
	try {
		let employee: EmployeeDTO = {
			matricule: matricule,
			id: "",
			lastName: "",
			firstName: "",
			companyId: await Service.getCompanyId(),
			address: "",
			phoneNumber: "",
			language: LANGUAGE.UNKNOWN,
		};

		const response = await EmployeeService.post(employee);
		if (response.status === 200) {
			return response.data;
		}
	} catch (e) {
		return undefined;
	}
}

function findNextScreen(configuration: ConfigurationDTO | undefined, order: number): ScreenDTO | undefined {
	let screen = configuration?.screens.find((elt) => elt.order === order);
	if (screen?.name === "summary") {
		return screen;
	}
	while (screen?.fields.filter((elt) => elt.isActive === true).length === 0) {
		order = order + 1;
		screen = configuration?.screens.find((elt) => elt.order === order);
		if (screen?.name === "summary") {
			return screen;
		}
	}
	return screen;
}

async function getCompanyIdByName(companyName: string) {
	const response = await CompanyService.getByName(companyName);

	return response.status === 200 ? response.data : undefined;
}

export { createEmployee, findNextScreen, getCompanyIdByName };
