import React, { FC, useReducer } from "react";
import { CertificateContext } from './certificateContext'
import certificateReducer, { initialCertificate } from "@reducers/certificateReducer";

const CertificateContextProvider: FC = ({ children }) => {
    //Attributes
    const [certificate, dispatch] = useReducer(certificateReducer.reducer, initialCertificate);

    //Render
    return (
        <CertificateContext.Provider value={{ certificate, dispatch }}>
            {children}
        </CertificateContext.Provider>
    );
}

export default CertificateContextProvider;
