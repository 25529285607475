import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { EntryTypeContext } from "./EntryTypeContext";
import EntryTypeService from "@services/EntryTypeService";
import { useConfiguration } from "@components/congifuration-context/ConfigurationContext";
import { TypeOfEntry } from "@dtos/EntryTypeDTO";

const EntryTypesContextProvider: FC = ({ children }) => {
  //Attributes
  const { configuration } = useConfiguration();
  const query = useQuery(`getEntryTypes-${configuration?.id}`, getEntryTypes);
  const [currentEntryType, setCurrentEntryType] = useState<
    TypeOfEntry | undefined
  >(undefined);

  //Effects
  useEffect(() => {
    query.refetch();
  }, [configuration?.id]);

  //Methods
  async function getEntryTypes(): Promise<any> {
    try {
      if (configuration?.id) {
        const response = await EntryTypeService.getAllEntriesByConfigId(
          configuration?.id ?? ""
        );
        return response.data;
      }
    } catch (e) {
      throw e;
    }
  }

  return (
    <EntryTypeContext.Provider
      value={{
        entryTypes: query.data,
        entryTypeQuery: query,
        currentEntryType,
        setCurrentEntryType,
      }}
    >
      {children}
    </EntryTypeContext.Provider>
  );
};

export default EntryTypesContextProvider;
