import './style.css';
import React from 'react';
import Box from '@components/box';
import Button from '@components/button';
import Stepper from '@components/stepper';
import PageBody from '@components/page-body';
import { createEmployee, findNextScreen } from '@utils/index';
import { useHistory } from 'react-router-dom';
import TranslationView from '@components/translation';
import BusyIndicator from '@components/busy-indicator';
import PageContainer from '@components/page-container';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import { useCompany } from '@modules/employee/modules/landing/components/context/companyContext';
import { useEntryTypes } from '@components/entry-types-context/EntryTypeContext';
import { TypeOfEntry } from '@dtos/EntryTypeDTO';

const Landing = () => {
  //Attributes
  const history = useHistory();
  const { company } = useCompany();
  const { query, configuration } = useConfiguration();
  const { entryTypes, setCurrentEntryType } = useEntryTypes();

  //Functions
  async function handleOnClick(entryType: TypeOfEntry) {
    setCurrentEntryType(entryType);
    const nextScreen = findNextScreen(configuration, 0);
    if (nextScreen) {
      if (nextScreen.name === 'matricule') {
        history.push('/employee');
      } else {
        const employee = await createEmployee();
        history.push(`/employee/${employee.id}/${nextScreen.name}`);
      }
    }
  }

  //Render
  return (
    <BusyIndicator query={query}>
      <div className='landing-container'>
        <PageBody>
          <PageContainer>
            <div className='companyLogo'>
              <img src={company?.logoURL} alt='logo' />
            </div>

            <Box type='boxlined'>
              <Box type='boxinside'>
                <h1>
                  <TranslationView>LandingMsg</TranslationView>
                </h1>
                <div className='landing-content'>
                  <p>
                    <span>
                      <TranslationView>introductionMsg</TranslationView>
                      {company?.name}
                    </span>
                  </p>
                </div>
                <div className='landing-buttons'>
                  {entryTypes?.map((elt) => (
                    <div
                      style={{
                        marginBottom: '10px',
                        display: elt.isActive ? 'flex' : 'none',
                      }}
                    >
                      <Button
                        type='white'
                        onClick={() => handleOnClick(elt.type)}
                      >
                        {elt.type === TypeOfEntry.ABSENCE_DECLARATION
                          ? 'btnRentrezDeclarationAbsence'
                          : 'btnRentrezCertificate'}
                      </Button>
                    </div>
                  ))}
                </div>
              </Box>
            </Box>
            <Stepper step={0} steps={configuration?.screens?.length} />
          </PageContainer>
        </PageBody>
      </div>
    </BusyIndicator>
  );
};

export default Landing;
