import './style.css';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@components/button';
import { useTranslation } from '@components/translation-context/TranslationContext';
import Input from '@components/input';
import TranslationView from '@components/translation';
import Box from '@components/box';
import Stepper from '@components/stepper';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import { useForm } from 'react-hook-form';
import { createEmployee, findNextScreen } from '@utils/index';
import BusyIndicator from '@components/busy-indicator';
import { useCertificate } from '../certificates/components/context/certificateContext';
import certificateReducer from '@reducers/certificateReducer';
import { toast } from "react-toastify";

const Detail = () => {
  //Attributes
  const history = useHistory();
  const [isBusy, setIsBusy] = useState(false);
  const { translate } = useTranslation();
  const { configuration } = useConfiguration();
  const { dispatch } = useCertificate();
  const { register, setValue, getValues, errors, handleSubmit, setError } =
    useForm();
  const [matricule, setMatricule] = useState<string>('');

  //Functions
  async function handleOnClick() {
    setIsBusy(true);

    if(configuration?.matriculeFormat){
      const regexMatricule = new RegExp(configuration?.matriculeFormat);
      if(!matricule.match(regexMatricule)){
        setError('matricule', { type: 'pattern' });
        setIsBusy(false);
        return;
      }
    }

    const employee = await createEmployee(matricule);
    if (employee) {
      dispatch({
        type: certificateReducer.actions.matriculeChanged,
        payload: employee.matricule,
      });
      const nextScreen = findNextScreen(configuration, 1);

      history.push(`/employee/${employee.id}/${nextScreen?.name}`);
    } else {
      setError('matricule', { type: 'pattern' });
    }
    setIsBusy(false);
  }

  function handleOnChange(event: any) {
    setMatricule(event.target.value);
  }

  function retrieveValue() {
    if (getValues('matricule')) {
      return getValues('matricule');
    }
  }

  function handleOnClickRetour() {
    history.goBack();
  }

  //Render
  return (
    <BusyIndicator isBusy={isBusy}>
      <Box>
        <Box type='boxinside'>
          <h3 className='detail-matricule-header'>
            <TranslationView>titleMatricule</TranslationView>
          </h3>
          <div className='detail-form'>
            <Input
              name='matricule'
              onChange={handleOnChange}
              type='text'
              value={retrieveValue()}
              setValue={setValue}
              placeholder={translate('matriculePlaceholder')}
              error={errors.matricule}
              errorMessage={
                errors.matricule?.type === 'pattern'
                  ? translate('patternError')
                  : translate('errorInput')
              }
              register={register}
              formProps={{ required: true }}
              style={{ width: '100%' }}
            />
            {configuration?.matriculeExampleUrl && (
              <>
                <div className='detail-matricule-title'>
                  <TranslationView>matriculeExplanationTitle</TranslationView>
                </div>
                <div className='detail-matricule-screenshot-container'>
                  <img
                    className='detail-matricule-screenshot-img'
                    src={configuration?.matriculeExampleUrl}
                    alt='matricule-screenshot'
                  />
                </div>
              </>
            )}
            <Button type='valider' onClick={handleSubmit(handleOnClick)}>
              validerPlaceholder
            </Button>
            <Button type='retour' onClick={handleOnClickRetour}>
              retourPlaceholder
            </Button>
          </div>
        </Box>
      </Box>
      <Stepper step={1} steps={7} />
    </BusyIndicator>
  );
};

export default Detail;
