import './style.css';
import React, { useEffect, useState } from 'react';
import Box from '@components/box';
import Button from '@components/button';
import Stepper from '@components/stepper';
import { useDropzone } from 'react-dropzone';
import TranslationView from '@components/translation';
import { useHistory, useParams } from 'react-router-dom';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import { useCertificate } from '../../components/context/certificateContext';
import DocumentDTO from '@dtos/DocumentDTO';
import DocumentService from '@services/DocumentService';
import certificateReducer from '@reducers/certificateReducer';
import BusyIndicator from '@components/busy-indicator';
import { CERTIFICATE_TYPE } from '@dtos/CertificateDTO';
import { toast } from 'react-toastify';
import { useTranslation } from '@components/translation-context/TranslationContext';
import heic2any from 'heic2any';

function toBase64(file: any): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		if (file) {
			reader.readAsDataURL(file);
		}
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
}

const Document = () => {
	// ATTRIBUTES
	const { id } = useParams();
	const history = useHistory();
	const [error, setError] = useState('');
	const [isBusy, setIsBusy] = useState(false);
	const { configuration } = useConfiguration();
	const { certificate, dispatch } = useCertificate();
	const [file, setFile] = useState<any | undefined>(undefined);
	const { getRootProps, getInputProps } = useDropzone({ onDrop });
	const [encodedFile, setEncodedFile] = useState(certificate.urlCertificate);
	const { translate } = useTranslation();
	const screenConfig = configuration?.screens.find(
		(elt) => elt.name === 'document'
	);
	const [HEICFileSrc, setHEICFileSrc] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);

	// EFFECTS
	useEffect(() => {
		if (
			certificate.certifType == CERTIFICATE_TYPE.JOUR_SANS_CERTIF &&
			screenConfig
		) {
			dispatch({
				type: certificateReducer.actions.urlCertificateChanged,
				payload:
					'https://medicheckcdn.blob.core.windows.net/images/no-certificate-image.jpeg',
			});
			history.push(`/employee/${id}/${screenConfig.nextScreenName}`);
		}
	}, []);

	useEffect(() => {
		// Used to show the image preview
		async function handleFileChanged() {
			if (file) {
				setIsLoading(true);
				setEncodedFile(await toBase64(file));
				setIsLoading(false);
			}
		}

		handleFileChanged();
	}, [file]);

	// FUNCTIONS
	async function handleOnClick() {
		try {
			// Verifies that the document page is mandatory
			// And that there is a encoded file previewed
			if (screenConfig && encodedFile) {
				setIsBusy(true);

				// If a file exists
				// Uploads it to the server
				// Stores it in the context
				if (encodedFile !== certificate.urlCertificate) {
					const resp = await createDocument();
					if (resp.status === 200) {
						dispatch({
							type: certificateReducer.actions.urlCertificateChanged,
							payload: resp.data.fileURL,
						});
					} else {
						setIsBusy(false);
						toast.warning(resp.data, {
							hideProgressBar: true,
							autoClose: 2000,
						});
						return;
					}
				}

				history.push(`/employee/${id}/${screenConfig.nextScreenName}`);
				setIsBusy(false);
			} else {
				setError('errorUpload');
			}
		} catch (e) {
			setIsBusy(false);
			toast.warning(translate('toastGenericError'), {
				hideProgressBar: true,
				autoClose: 2000,
			});
		}
	}

	async function createDocument() {
		const encodedFile = await toBase64(file);
		const extensionIndex = (file.name as string).lastIndexOf('.');

		const document: DocumentDTO = {
			file: encodedFile.split(',')[1],
			fileName: file.name,
			fileExtension: (file.name as string).substr(extensionIndex),
			fileUrl: '',
		};
		dispatch({
			type: certificateReducer.actions.fileNameChanged,
			payload: document.fileName,
		});

		return await DocumentService.post(document, id);
	}

	function onDrop(acceptedFiles: any) {
		setFile(acceptedFiles[0]);
	}

	async function getEncodedFileHEIC() {
		// Retrieve the encoded file asynchronously
		const response = await fetch(encodedFile);
		const blob = await response.blob();

		//Converts HEIC (High-Efficiency Image Format) files to a jpeg image format
		const jpegBuffer = await heic2any({
			blob: new Blob([blob], { type: 'image/heic' }),
			toType: 'image/jpeg',
		});
		const convertedBlob = Array.isArray(jpegBuffer)
			? jpegBuffer[0]
			: jpegBuffer;
		return URL.createObjectURL(convertedBlob);
	}

	async function renderHEICFile() {
		if (encodedFile && encodedFile?.includes('image/heic')) {
			setIsLoading(true);
			const resolvedSrc = await getEncodedFileHEIC();
			setHEICFileSrc(resolvedSrc);
			setIsLoading(false);
		} else {
			setHEICFileSrc(undefined);
		}
	}

	useEffect(() => {
		renderHEICFile();
	}, [encodedFile]);

	function renderImage() {
		if (encodedFile?.includes('application/pdf')) {
			return (
				<div>
					<TranslationView>documentReceived</TranslationView>
				</div>
			);
		} else if (HEICFileSrc) {
			return <img src={HEICFileSrc} alt='' />;
		} else {
			return <img src={encodedFile} alt='' />;
		}
	}

	return (
		<BusyIndicator isBusy={isBusy}>
			<Box>
				<Box type='boxinside'>
					<h3>
						<TranslationView>documentTitle</TranslationView>
					</h3>

					<div className='document-title'>
						<TranslationView>documentSubTitle</TranslationView>
					</div>

					<div className='dropzone-container'>
						<div className='dropzone-container-body' {...getRootProps()}>
							<input
								className='dropzone-container-body-input'
								{...getInputProps()}
							/>
							<div
								className={`document-container ${
									isLoading ? '' : 'document-background'
								}`}
							>
								<BusyIndicator isBusy={isLoading}>
									{renderImage()}
								</BusyIndicator>
							</div>
						</div>
					</div>
					<div>
						<p className='upload-error'>
							<TranslationView>{error}</TranslationView>
						</p>
					</div>

					<div className='document-buttons'>
						<Button type='valider' onClick={handleOnClick}>
							validerPlaceholder
						</Button>

						<Button type='retour' onClick={history.goBack}>
							retourPlaceholder
						</Button>
					</div>
				</Box>
			</Box>
			<Stepper
				step={(screenConfig?.order ?? 0) + 1}
				steps={configuration?.screens?.length}
			/>
		</BusyIndicator>
	);
};

export default Document;
