import React from 'react';

// style
import './style.css'

const PageBackground = ({ children }: any) => {
    return (
        <div className='background'>
            {children}
        </div>
    )
}

export default PageBackground;