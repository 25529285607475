import './style.css';
import React from 'react';
import InputGroupProps from './props';

const InputGroup = (props: InputGroupProps) => {
    return (
        <div className="input-group">
            {props.children}
        </div>
    );
}

export default InputGroup;