import BusyIndicator from '@components/busy-indicator';
import CertificateService from '@services/CertificateService';
import React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import Detail from './modules/detail';
import Error from './modules/error';
import Success from './modules/success';

const Request = () => {
  //Attributes
  const history = useHistory();
  const { id, certificateId }: any = useParams();
  const query = useQuery('getCertificate', getCertificate);

  //Effects
  useEffect(() => {
    if (query.data?.isCheckRequestLaunched) {
      history.push(`/employee/${id}/certificate/${certificateId}/error`);
    }
  }, [query.data]);

  // Functions
  async function getCertificate() {
    const response = await CertificateService.get(id, certificateId);
    if (response && response.data) {
      return response.data;
    }
  }

  //Render
  return (
    <BusyIndicator query={query}>
      <Switch>
        <Route
          exact
          path='/employee/:id/certificate/:certificateId'
          component={Detail}
        />
        <Route
          path='/employee/:id/certificate/:certificateId/success'
          component={Success}
        />
        <Route
          path='/employee/:id/certificate/:certificateId/error'
          component={Error}
        />
        <Route
          default
          render={() => (
            <Redirect to={`/employee/${id}/certificate/${certificateId}`} />
          )}
        />
      </Switch>
    </BusyIndicator>
  );
};

export default Request;
