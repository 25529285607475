import React, { useRef } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import Input from "@components/input";
import { useTranslation } from "@components/translation-context/TranslationContext";
import './style.css'
import { useCertificate } from "@modules/employee/modules/certificates/components/context/certificateContext";
import certificateReducer from "@reducers/certificateReducer";
import useOutsideClick from "../../hooks/useOutsideClick";
import Suggestion from "./components/suggestion";
import PlacesAutocompleteProps from "./props";

const PlacesAutocomplete = (props: PlacesAutocompleteProps) => {
    // Attributes
    const {
        // setValue,
        value,
        screenConfig,
        errors,
        register
    } = props;

    const ref = useRef<any>();
    const { translate } = useTranslation();
    const { dispatch } = useCertificate();

    const { suggestions: { data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });

    //Hooks
    useOutsideClick(ref, clearSuggestions);

    // Handlers
    const handleInput = (e: any) => {
        setValue(e.target.value);
        const action = { type: certificateReducer.actions.addressChanged, payload: e.target.value };
        dispatch(action);
    };

    const handleSelect = ({ description }: any) => () => {
        props.setValue("address", description);
        const action = { type: certificateReducer.actions.addressChanged, payload: description };
        dispatch(action);
        clearSuggestions();
    };

    //Render
    return (
        <div ref={ref} className="autocomplete-container">
            <Input
                type="text"
                name="address"
                isHidden={!(screenConfig?.fields?.find((elt: any) => elt.name.toLowerCase() === "address")?.isActive)}
                value={value}
                setValue={props.setValue}
                onChange={(event) => handleInput(event)}
                placeholder={translate('addressPlaceholder')}
                error={errors.address}
                errorMessage={errors.address?.type === "pattern" ? translate('patternError') : translate('errorInput')}
                register={register}
                formProps={{ required: (screenConfig?.fields?.find((elt: any) => elt.name.toLowerCase() === "address")?.isRequired) }}
                style={{width: '100%'}}
            />

            <ul className="suggestionUl" style={{ width: (ref?.current?.clientWidth - 1) ?? "", display: data.length > 0 ? 'initial' : 'none' }}>
                {
                    data.map((suggestion) => <Suggestion suggestion={suggestion} handleSelect={handleSelect} />)
                }
            </ul>
        </div>
    );
};

export default PlacesAutocomplete;